import React from 'react';
import { XMarkIcon, ArrowDownCircleIcon } from '@heroicons/react/24/solid'
import List from './finder/List';
import ListSelected from './finder/ListSelected';
import Overview from './finder/Overview';
import { Dropdown } from "flowbite-react";
function Mobile({
	MONTHS,
	LEVELS,
	SORTING,
	customTheme,
	sorts,
	onSortingClicked,
	onSearch,
	search,
	count,
	onUniversitySearch,
	filteredUniversities,
	filters,
	onFiltersClicked,
	onDepartmentSearch,
	departments,
	onCitySearch,
	cities,
	onClickClearAll,
	course,
	courses,
	setCourse,
	coursesResponse,
	loading,
	lastElementRef,
	lastElementRefM,
	removeFilterClicked,
	hasMore
}) {
	return (
		<div>
			<div className="flex flex-col gap-4 justify-between py-4">
				<div className='flex flex-col gap-4'>
					<form className="flex px-5">
						<label htmlFor="simple-search" className="sr-only">Search</label>
						<div className="relative w-full">
							<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
								<svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
								</svg>
							</div>
							<input onChange={(e) => onSearch(e)} value={search} type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search course..." />
						</div>
					</form>
					<div className="flex gap-1 divide-x divide-solid divide-slate-300 bg-slate-100 overflow-scroll">
						<Dropdown
							theme={customTheme}
							label="University"
							dismissOnClick={false}
							renderTrigger={() => 
								<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-2 text-center inline-flex items-center">
									University
									{
										count.university ?
										<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.university}</span>
										: ''
									}
									<svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
										<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
									</svg>
								</span>
							}>
							<div className="p-3">
								<label htmlFor="input-group-search" className="sr-only">Search universities</label>
								<div className="relative">
									<div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
									<svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
										<path stroke="currentColor" strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
									</svg>
									</div>
									<input onKeyDown={(e) => e.stopPropagation()} onChange={(e) => onUniversitySearch(e)} type="text" id="input-group-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search universities" />
								</div>
							</div>
							{filteredUniversities?.map((university, universityKey) => {
								return (
									<Dropdown.Item key={universityKey} title={university.institute_name}>
										{ filters.some(e => e.id === university.id) ? <input onClick={(e) => onFiltersClicked(e, {id: university.id, label: university.institute_name, model: 'university'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
										<input onClick={(e) => onFiltersClicked(e, {id: university.id, label: university.institute_name, model: 'university'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
										<label onClick={(e) => onFiltersClicked(e, {id: university.id, label: university.institute_name, model: 'university'})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{university.institute_name}</label>
									</Dropdown.Item>
								)
							})}
						</Dropdown>
						<Dropdown
							theme={customTheme}
							label="Level"
							dismissOnClick={false}
							renderTrigger={() => 
								<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-2 text-center inline-flex items-center">
									Level
									{
										count.level ?
										<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.level}</span>
										: ''
									}
									<svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
										<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
									</svg>
								</span>
							}>
							{LEVELS?.map((level, levelKey) => {
								return (
									<Dropdown.Item key={levelKey} title={level.label}>
										{ filters.some(e => e.id === level.id) ? <input onClick={(e) => onFiltersClicked(e, {id: level.id, label: level.label, model: 'level'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
										<input onClick={(e) => onFiltersClicked(e, {id: level.id, label: level.label, model: 'level'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
										<label onClick={(e) => onFiltersClicked(e, {id: level.id, label: level.label, model: 'level'})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{level.label}</label>
									</Dropdown.Item>
								)
							})}
						</Dropdown>
						<Dropdown
							theme={customTheme}
							label="Department"
							dismissOnClick={false}
							renderTrigger={() => 
								<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-2 text-center inline-flex items-center">
									Department
									{
										count.departments ?
										<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.departments}</span>
										: ''
									}
									<svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
										<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
									</svg>
								</span>
							}>
							<div className="p-3">
								<label htmlFor="input-group-search" className="sr-only">Search departments</label>
								<div className="relative">
									<div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
									<svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
										<path stroke="currentColor" strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
									</svg>
									</div>
									<input onKeyDown={(e) => e.stopPropagation()} onChange={(e) => onDepartmentSearch(e)} type="text" id="input-group-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search departments" />
								</div>
							</div>
							{departments?.map((department, departmentKey) => {
								return (
									<Dropdown.Item key={departmentKey} title={department.label}>
										{ filters.some(e => e.id === department.id) ? <input onClick={(e) => onFiltersClicked(e, {id: department.id, label: department.label, model: 'departments'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
										<input onClick={(e) => onFiltersClicked(e, {id: department.id, label: department.label, model: 'departments'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
										<label onClick={(e) => onFiltersClicked(e, {id: department.id, label: department.label, model: 'departments'})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{department.label}</label>
									</Dropdown.Item>
								)
							})}
						</Dropdown>
						<Dropdown
							theme={customTheme}
							label="City"
							dismissOnClick={false}
							renderTrigger={() => 
								<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-2 text-center inline-flex items-center">
									City
									{
										count.city ?
										<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.city}</span>
										: ''
									}
									<svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
										<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
									</svg>
								</span>
							}>
							<div className="p-3">
								<label htmlFor="input-group-search" className="sr-only">Search cities</label>
								<div className="relative">
									<div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
									<svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
										<path stroke="currentColor" strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
									</svg>
									</div>
									<input onKeyDown={(e) => e.stopPropagation()} onChange={(e) => onCitySearch(e)} type="text" id="input-group-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search cities" />
								</div>
							</div>
							{cities?.map((city, cityKey) => {
								return (
									<Dropdown.Item key={cityKey} title={city.city}>
										{ filters.some(e => e.id === city.city) ? <input onClick={(e) => onFiltersClicked(e, {id: city.city, label: city.city, model: 'cities'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
										<input onClick={(e) => onFiltersClicked(e, {id: city.city, label: city.city, model: 'cities'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
										<label onClick={(e) => onFiltersClicked(e, {id: city.city, label: city.city, model: 'cities'})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{city.city}</label>
									</Dropdown.Item>
								)
							})}
						</Dropdown>
						<Dropdown
							theme={customTheme}
							label="Intake"
							dismissOnClick={false}
							renderTrigger={() => 
								<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-2 text-center inline-flex items-center">
									Intake
									{
										count.intake ?
										<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.intake}</span>
										: ''
									}
									<svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
										<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
									</svg>
								</span>
							}>
							{MONTHS?.map((intake, intakeKey) => {
								return (
									<Dropdown.Item key={intakeKey} title={intake.label}>
										{ filters.some(e => e.id === intake.id) ? <input onClick={(e) => onFiltersClicked(e, {id: intake.id, label: intake.label, model: 'intake'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
										<input onClick={(e) => onFiltersClicked(e, {id: intake.id, label: intake.label, model: 'intake'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
										<label onClick={(e) => onFiltersClicked(e, {id: intake.id, label: intake.label, model: 'intake'})} htmlFor="checkbox-item-1" className="w-full ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate text-left">{intake.label}</label>
									</Dropdown.Item>
								)
							})}
						</Dropdown>
					</div>
				</div>
				<div className="flex gap-4 justify-end">
					<Dropdown
						theme={customTheme}
						label="Sort"
						dismissOnClick={false}
						renderTrigger={() => 
							<span className="cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-0 text-center inline-flex items-center">
								Sort
								<svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
								</svg>
							</span>
						}>
						{SORTING?.map((sort, sortKey) => {
							return (
								<Dropdown.Item key={sortKey} title={sort.label}>
									{ sorts.some(e => e.id === sort.id) ? <input onClick={(e) => onSortingClicked(e, {id: sort.id, label: sort.label, order: sort.order})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
									 <input onClick={(e) => onSortingClicked(e, {id: sort.id, label: sort.label, order: sort.order})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
									<label onClick={(e) => onSortingClicked(e, {id: sort.id, label: sort.label, order: sort.order})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{sort.label}</label>
								</Dropdown.Item>
							)
						})}
					</Dropdown>
				</div>
			</div>
			<div className="flex bg-slate-200 items-center justify-between px-2 py-4 w-full divide-x divide-solid divide-slate-300">
				<p className="flex align-center justify-center text-md leading-6 font-medium text-slate-600 px-1 py-0 w-[70px]">Filter</p>
				<div className="flex flex-1 px-5 py-0 gap-2 overflow-y-auto whitespace-nowrap">
					{
						filters.map((filter, filterKey) => <button key={filterKey} className="flex shadow-sm items-center gap-2 rounded-full bg-white px-4 py-1 font-medium text-slate-600" onClick={() => removeFilterClicked(filter)}><label className="truncate max-w-40">{filter.label}</label> <XMarkIcon className="size-4 text-slate-600" /></button>)
					}
				</div>
				<div className="flex items-center justify-between w-[100px] ps-2">
					<button onClick={() => onClickClearAll()} className="bg-transparent px-1 py-1">Clear all</button>
				</div>
			</div>
			{
				course ?
					<div className="fixed top-0 bottom-0 overflow-x-scroll overflow-x-hidden bg-white">
						<Overview course={course} />
					</div> :
					<div className="flex flex-col w-full">
						<List courses={courses} setCourse={setCourse} loading={loading} onClickClearAll={onClickClearAll} />
						{!loading && coursesResponse.length ? hasMore ? <div ref={lastElementRefM} className="flex items-center justify-center my-6">
							<ArrowDownCircleIcon className="size-16 animate-bounce text-gray-500 stroke-2" />
						</div> : <div ref={lastElementRef} className="flex items-center justify-center my-6">
							<p className="flex gap-1 text-md leading-6 text-gray-700">No more courses</p>
						</div> : null}
					</div>
			}
		</div>
	);
}

export default Mobile;
