const formatSnakeCaseString = (input) => {
    // Split by underscores if present; otherwise, split by spaces
    const words = input.includes('_') ? input.split('_') : input.split(' ');
  
    return words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize each word
      .join(' ');                           // Join words with spaces
};

const formatCommaString = (input) => {
    return input
      .split(',')                                      // Split by commas
      .map(month => month.trim())                      // Remove any extra spaces
      .map(month => month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()) // Capitalize first letter
      .join(', ');                                     // Join back with comma and space
  }

const utils = {
    formatSnakeCaseString,
    formatCommaString
};

export default utils;