import React from 'react';
import { useSelector } from 'react-redux'
import { TagIcon, CalendarIcon, CurrencyPoundIcon, ClockIcon } from '@heroicons/react/24/outline'
import University from './University';
import utils from "../../utils/format";

function Overview({ course }) {
    const university = useSelector((state) => state.course.university)
    const loading = useSelector((state) => state.course.universityLoading)
    if(loading) {
        return (
            <div className="flex w-full flex-col dark:bg-slate-900">
                <div className="flex flex-col g-2 py-5 px-10 bg-slate-100">
                    <p className="text-sm leading-6 text-gray-700 font-semibold">{course?.institutes?.name} (#{course?.institutes?.rank_the})</p>
                    <p className="text-4xl leading-10 text-gray-900 font-bold">{course.name}</p>
                </div>
                <div className="flex flex-col py-5 px-10 gap-10">
                    <div className="flex flex-col g-2 gap-4">
                        <p className="text-2xl leading-8 text-gray-900 font-bold">Overview</p>
                        <div className="animate-pulse flex space-x-4">
                            <div className="flex-1 space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div className="h-2 bg-slate-200 rounded"></div>
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                </div>
                                <div className="h-2 bg-slate-200 rounded"></div>
                                <div className="h-2 bg-slate-200 rounded"></div>
                                <div className="grid grid-cols-4 gap-4">
                                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col g-2 gap-4">
                        <p className="text-2xl leading-8 text-gray-900 font-bold">Highlights</p>
                        <div className="flex justify-evenly gap-4 animate-pulse">
                            <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                                <TagIcon className="size-6 text-gray-500" />
                                <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                                <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                            </div>
                            <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                                <CalendarIcon className="size-6 text-gray-500" />
                                <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                                <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                            </div>
                            <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                                <CurrencyPoundIcon className="size-6 text-gray-500" />
                                <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                                <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                            </div>
                            <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                                <ClockIcon className="size-6 text-gray-500" />
                                <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                                <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                            </div>
                        </div>
                    </div>
                    <University course={course} university={course?.institutes} loading={loading} />
                </div>
            </div>
        );
    }
    return (
        <div className="flex h-full w-full flex-col dark:bg-slate-900">
            <div className="flex flex-col g-2 py-5 px-5 bg-slate-100">
                    <p className="text-sm leading-6 text-gray-700 font-semibold">{course?.institutes?.name} (#{course?.institutes?.rank_the})</p>
                    <p className="text-4xl leading-10 text-gray-900 font-bold">{course.name}</p>
            </div>
            <div className="flex flex-col py-5 px-5 gap-10">
                <div className="flex flex-col g-2 gap-4">
                    <p className="text-2xl leading-8 text-gray-900 font-bold">Overview</p>
                    <p className="text-md leading-6 text-gray-700 font-medium">{course?.about}</p>
                </div>
                <div className="flex flex-col g-2 gap-4">
                    <p className="text-2xl leading-8 text-gray-900 font-bold">Highlights</p>
                    <div className="flex items-center gap-6">
                        <div className="flex flex-col gap-5 border border-1 p-3">
                            <div className="flex justify-between gap-1">
                                <p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><TagIcon className="size-6 text-gray-500" /> Course Category:</p>
                                <p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.department_name ? utils.formatSnakeCaseString(course.department_name) : 'NA'}</p>
                            </div>
                            <div className="flex justify-between gap-1">
                                <p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><CalendarIcon className="size-6 text-gray-500" /> Next Intake:</p>
                                <p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.intake ? utils.formatCommaString(course.intake) : 'NA'}</p>
                            </div>
                            <div className="flex justify-between gap-1">
                                <p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><CurrencyPoundIcon className="size-6 text-gray-500" /> Annual Cost:</p>
                                <p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.course_fee ? `$${course.course_fee}` : 'NA'}</p>
                            </div>
                            <div className="flex justify-between gap-1">
                                <p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><ClockIcon className="size-6 text-gray-500" /> Duration:</p>
                                <p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.course_duration ? `${course.course_duration} ${course.course_duration_period}` : 'NA'}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <University course={course} university={course?.institutes} loading={loading} />
            </div>
        </div>
    );
}

export default Overview;
