import React from 'react';
import { TagIcon, CalendarIcon, CurrencyPoundIcon, ClockIcon } from '@heroicons/react/24/outline'

function University({ course, university, loading }) {
    console.log("university>>>", university);
    if(loading) {
        return (
            <div className="flex flex-col gap-10">
                <div className="flex flex-col g-2 gap-4">
                    <p className="text-2xl leading-8 text-gray-900 font-bold">About {course.institute_name}</p>
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-200 rounded"></div>
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                            </div>
                            <div className="h-2 bg-slate-200 rounded"></div>
                            <div className="h-2 bg-slate-200 rounded"></div>
                            <div className="grid grid-cols-4 gap-4">
                                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col g-2 gap-4">
                    <p className="text-2xl leading-8 text-gray-900 font-bold">Highlights</p>
                    <div className="flex flex-wrap justify-evenly gap-4 animate-pulse">
                        <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-2">
                            <TagIcon className="size-6 text-gray-500" />
                            <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                            <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                        </div>
                        <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                            <CalendarIcon className="size-6 text-gray-500" />
                            <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                            <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                        </div>
                        <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                            <CurrencyPoundIcon className="size-6 text-gray-500" />
                            <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                            <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                        </div>
                        <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                            <ClockIcon className="size-6 text-gray-500" />
                            <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                            <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                        </div>
                        <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                            <ClockIcon className="size-6 text-gray-500" />
                            <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                            <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                        </div>
                        <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                            <ClockIcon className="size-6 text-gray-500" />
                            <div className="h-2 bg-slate-200 rounded w-[100px]"></div>
                            <div className="h-2 bg-slate-200 rounded w-[50px]"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="flex flex-col gap-10">
            <div className="flex flex-col g-2 gap-4">
                <p className="text-2xl leading-8 text-gray-900 font-bold">About {course.institute_name}</p>
                <p className="text-md leading-6 text-gray-700 font-medium">{university?.about}</p>
            </div>
            <div className="flex flex-col g-2 gap-4">
                <p className="text-2xl leading-8 text-gray-900 font-bold">Highlights</p>
                <div className="flex flex-wrap justify-evenly gap-4">
                    <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                        <TagIcon className="size-6 text-gray-500" />
                        <p className="text-center text-md leading-6 text-gray-900 font-medium">Establisted Year</p>
                        <p className="text-center text-sm leading-6 text-gray-500 font-medium">{university?.established_year ? university.established_year : 'NA'}</p>
                    </div>
                    <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                        <CalendarIcon className="size-6 text-gray-500" />
                        <p className="text-center text-md leading-6 text-gray-900 font-medium">Employability</p>
                        <p className="text-center text-sm leading-6 text-gray-500 font-medium">{university?.employability_percentage ? university.employability_percentage : 'NA'}</p>
                    </div>
                    <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                        <CurrencyPoundIcon className="size-6 text-gray-500" />
                        <p className="text-center text-md leading-6 text-gray-900 font-medium">No. Of Students</p>
                        <p className="text-center text-sm leading-6 text-gray-500 font-medium">{university?.no_of_students ? university.no_of_students : 'NA'}</p>
                    </div>
                    <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                        <ClockIcon className="size-6 text-gray-500" />
                        <p className="text-center text-md leading-6 text-gray-900 font-medium">Internship</p>
                        <p className="text-center text-sm leading-6 text-gray-500 font-medium">{university?.internship ? university.internship : 'NA'}</p>
                    </div>
                    <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                        <ClockIcon className="size-6 text-gray-500" />
                        <p className="text-center text-md leading-6 text-gray-900 font-medium">Scholarship</p>
                        <p className="text-center text-sm leading-6 text-gray-500 font-medium">{university?.scholarship ? university.scholarship : 'NA'}</p>
                    </div>
                    <div className="w-[200px] h-[150px] flex flex-col py-5 px-2.5 bg-slate-100 items-center justify-center rounded-md border border-slate-300 gap-1">
                        <ClockIcon className="size-6 text-gray-500" />
                        <p className="text-center text-md leading-6 text-gray-900 font-medium">Job Support</p>
                        <p className="text-center text-sm leading-6 text-gray-500 font-medium">{university?.job_support ? university.job_support : 'NA'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default University;
